import { defineStore } from "pinia";
import { DebugLevel, appLog } from "../functions/Logging";

const storeName = "ScrollStore";

appLog(DebugLevel.INFO, "[Pinia]", storeName + " initializing ...");

export const useScrollStore = defineStore({
  id: "scroll",
  state: () => ({
    YposMenuRight: 0,
    YposMenuMain: 0,
    scrollElementMain: "",
  }),
});

appLog(DebugLevel.INFO, "[Pinia]", storeName + " initialized.");
