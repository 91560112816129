import languages from "../data/config/languages.json";
import appInfo from "../data/config/app.json";
import bibleTranslations from "../data/config/bible_translations.json";
import bibleTranslationsDefault from "../data/config/bible_translations_default.json";
import darkModeDefault from "../data/config/dark_mode_default.json";
import baseFontSizeDefault from "../data/config/base_font_size_default.json";
import tunes from "../data/tunes.json";
import roles from "../data/roles.json";
import refrains from "../data/refrains.json";
import liturgy_services from "../data/liturgy_services.json";
import readings from "../data/lesungen/readings.json";
import readingTypes from "../data/lesungen/reading_types.json";
import matins from "../data/euchologion/matins.json";
import vesper from "../data/euchologion/vesper.json";
import liturgy from "../data/euchologion/liturgy.json";

function getUILanguages() {
  return languages?.filter((a) => a.uiLanguage) || [];
}

function getTextLanguages() {
  return languages?.filter((a) => a.textLanguage) || [];
}

function getDefaultUiLanguage() {
  return languages?.filter((a) => a.uiLanguageDefault) || [];
}

function getDefaultTextLanguages() {
  return languages?.filter((a) => a.displayDefault) || [];
}

function getLanguageByKey(key) {
  return languages?.find((obj) => obj.key === key) || null;
}

function getTuneByKey(key) {
  return tunes?.find((obj) => obj.key === key) || {};
}

function getRefrainsByKey(key) {
  return refrains?.find((obj) => obj.key === key) || null;
}

function getReadingTypeByKey(key) {
  return readingTypes?.find((obj) => obj.key === key) || null;
}

function getLiturgyServices() {
  return readings?.books?.map((book) => book.key);
}

export const configs = {
  languages,
  appInfo,
  bibleTranslations,
  bibleTranslationsDefault,
  darkModeDefault,
  baseFontSizeDefault,
  tunes,
  roles,
  refrains,
  readings,
  readingTypes,
  matins,
  vesper,
  liturgy,
  liturgy_services,
  getUILanguages,
  getTextLanguages,
  getDefaultUiLanguage,
  getDefaultTextLanguages,
  getLanguageByKey,
  getTuneByKey,
  getRefrainsByKey,
  getReadingTypeByKey,
};
