<script>
export default {
  name: "BaseLayout",
};
</script>
<script setup>
import {
  IonMenuButton,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { DebugLevel, appLog } from "@/functions/Logging";
import { hooks } from "@/functions/Hooks";
import { chevronBackOutline, eyeSharp } from "ionicons/icons";
import { useRoute } from "vue-router";

import DisplaySettingModal from "./DisplaySettingModal";

// eslint-disable-next-line no-unused-vars
import VarusChurchSeasonSwitch from "../VarusChurchSeasonSwitch.vue";

// Pinia - ScrollStore
import { useScrollStore } from "@/store/scrollStore.js";
const scrollStore = useScrollStore();

// Logging
const compName = "[BaseLayout]";

// PROPS
defineProps({
  pageTitle: String,
  displayBackLink: String,
  pageDefaultBackLink: String,
  useHistory: Boolean,
});

// Modal
const openModal = async () => {
  const modal = await modalController.create({ component: DisplaySettingModal });
  modal.present();
  await modal.onWillDismiss();
};

function logScrolling(event) {
  scrollStore.YposMenuMain = event.detail.currentY;
  appLog(DebugLevel.SCROLL, compName, "Scrolling to YposMenuMain: " + event.detail.currentY);
}

// Hooks
hooks(compName);
</script>

<template>
  <ion-page ref="page">
    <ion-header :translucent="true" mode="md">
      <ion-toolbar class="header">
        <ion-buttons slot="start">
          <ion-menu-button menu="left-menu"></ion-menu-button>
          <ion-back-button
            v-if="useHistory && displayBackLink === 'true'"
            :default-href="pageDefaultBackLink"
          ></ion-back-button>
          <ion-button v-if="!useHistory && displayBackLink === 'true'" :router-link="pageDefaultBackLink">
            <ion-icon slot="start" :icon="chevronBackOutline" />
          </ion-button>
        </ion-buttons>
        <ion-title id="headerTitle">{{ pageTitle }}</ion-title>

        <!--varus-church-season-switch/-->

        <!-- Eye Icon for View Settings -->
        <ion-icon
          v-if="useRoute().meta.showDisplayOptionsIcon"
          slot="end"
          :icon="eyeSharp"
          expand="block"
          style="margin-right: 60px"
          @click="openModal"
        />

        <ion-buttons slot="end">
          <ion-menu-button menu="right-menu"></ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true" @ionScroll="logScrolling($event)">
      <slot />
    </ion-content>
  </ion-page>
</template>
<style scoped>
.header {
  color: white;
  background-color: transparent;
}
ion-modal {
  --width: 400px;
  --height: 50%;
}
</style>
