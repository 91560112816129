import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import { DebugLevel, appLog } from "../functions/Logging";
import loadingService from "../services/loadingService";
import { useBookStore } from "../store/bookStore";

const home = "home";
const reading = "reading";
const booklist = "booklist";
const booklist2 = "booklist2";
const booklist3 = "booklist3";
const prayerlist = "prayerlist";

const routes = [
  {
    path: "/",
    redirect: `/${home}`,
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/calendar",
    component: () => import("../views/CopticCalendar.vue"),
    name: "calendar",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/settings",
    component: () => import("../views/Settings.vue"),
    name: "settings",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/info",
    component: () => import("../views/Info.vue"),
    name: "info",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: `/${home}`,
    component: () => import("../views/Home.vue"),
    name: home,
    meta: {
      showRightMenu: false,
      showDisplayOptionsIcon: false,
      showLoadingIndicator: false,
      resetBookSelection: true,
    },
  },
  {
    path: `/${booklist}`,
    redirect: `/${booklist}/books`,
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: `/${booklist}/:key`,
    component: () => import("../views/ListBooks.vue"),
    props: { listview: 1 },
    name: booklist,
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: `/${booklist2}/:key`,
    component: () => import("../views/ListBooks.vue"),
    props: { listview: 2 },
    name: booklist2,
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: `/${booklist3}/:key`,
    component: () => import("../views/ListBooks.vue"),
    props: { listview: 3 },
    name: booklist3,
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: `/${prayerlist}/:key`,
    component: () => import("../views/ListPrayers.vue"),
    name: prayerlist,
    meta: { showRightMenu: true, showDisplayOptionsIcon: true, showLoadingIndicator: true },
  },
  {
    path: `/${reading}/:key`,
    component: () => import("../views/Reading.vue"),
    name: reading,
    meta: { showRightMenu: true, showDisplayOptionsIcon: true, showLoadingIndicator: true },
  },
  {
    path: "/churchSeason",
    component: () => import("../views/ChurchSeason.vue"),
    name: "churchSeason",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/devinfo",
    component: () => import("../views/DevelopmentInfo.vue"),
    name: "devinfo",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/test",
    component: () => import("../views/TestPage.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: true, showLoadingIndicator: false },
  },
  {
    path: "/test2",
    component: () => import("../views/TestPage2.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: true, showLoadingIndicator: false },
  },
  {
    path: "/seasonAndTuneOfYear",
    component: () => import("../views/SeasonAndTuneOfYear.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/colors",
    component: () => import("../views/Colors.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/grid",
    component: () => import("../views/Grid.vue"),
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
  {
    path: "/reportbug",
    component: () => import("../views/ReportBug.vue"),
    name: "reportbug",
    meta: { showRightMenu: false, showDisplayOptionsIcon: false, showLoadingIndicator: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    appLog(DebugLevel.ROUTER, "[ROUTER]", "from: ", from);
    appLog(DebugLevel.ROUTER, "[ROUTER]", "to: ", to);
    if (to.hash) {
      const el = to.hash.substring(1); // Remove '#' to get the element ID
      appLog(DebugLevel.ROUTER, "[ROUTER]", "Target element ID:", el);

      const targetEl = document.getElementById(el);
      if (targetEl) {
        appLog(DebugLevel.ROUTER, "[ROUTER]", "Found element:", targetEl);
        // scroll to the element
        targetEl.scrollIntoView({});
      } else {
        appLog(DebugLevel.ERROR, "[ROUTER]", "Element not found:", el);
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      document.getElementById("app").scrollIntoView();
    }
  },
  // check this:
  // https://stackoverflow.com/questions/71196755/linking-router-link-and-scrollbehavior-not-working-vue-3-and-ionic-6/71199653#71199653
});

let loader;

router.beforeEach(async (to, from, next) => {
  // Logging
  appLog(DebugLevel.ROUTER, "[ROUTER Navigation Guard]", `beforeEach | Route "${from.path}" to "${to.path}"`, from, to);

  // show loading indicator
  if (to.meta.showLoadingIndicator) {
    loader = await loadingService.presentLoading();
  }

  // Reset Book selection
  if (to.meta.resetBookSelection) {
    // Book Store
    // access the book-store here and not above, because only the router starts its navigation after
    // the router is installed and pinia will be installed too
    // see https:/ / pinia.vuejs.org / core - concepts / outside - component - usage.html;
    const bookStore = useBookStore();
    bookStore.clearSelectedBook();
  }
  next();
});

router.afterEach((to, from) => {
  // Logging
  appLog(DebugLevel.ROUTER, "[ROUTER Navigation Guard]", `afterEach | Route "${from.path}" to "${to.path}"`, from, to);

  // hide loading indicator
  loadingService.dismissLoading(loader);

  // Book Store
  const bookStore = useBookStore();

  // load or filter data of bookStore based on the navigated route
  switch (to.name) {
    case home:
      // set selected book to null
      bookStore.loadBook();
      break;
    case booklist:
    case booklist2:
    case booklist3:
      // load selected book
      bookStore.loadBook(to.params.key);
      break;
    case prayerlist:
      // filter prayers based on selected book
      bookStore.filterPrayers(to.params.key);
      break;
    default:
  }
});

export default router;
