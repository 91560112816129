import { defineStore } from "pinia";
import { DebugLevel, appLog } from "../functions/Logging";
import { useCalendarStore } from "../store/calendarStore";
import { useGetFilteredPrayers } from "../composables/PrayerFilterService.js";

// Constants
const storeName = "BookStore";
appLog(DebugLevel.INFO, "[Pinia]", `${storeName} initializing ...`);

// Not to fileMap:
// The application requires dynamically importing a large number of JSON files from the local file system.
// Since Vite does not support the use of the CommonJS require() function, we must use the import() function with ECMAScript module syntax.
// However, the import() function requires paths to be statically analyzable at build time, which means it does not support fully dynamic paths.
// Solution:
// To handle dynamic imports, all possible paths must be predefined as static imports here.
// We can then use a mapping object to access the desired imports dynamically.
const fileMap = {
  books: () => import("../data/books.json"),
  agpeya: () => import("../data/agpeya/agpeya.json"),
  paragraphs_general: () => import("../data/paragraphs_general.json"),

  agpeya1: () => import("../data/agpeya/agpeya1.json"),
  agpeya2: () => import("../data/agpeya/agpeya2.json"),
  agpeya3: () => import("../data/agpeya/agpeya3.json"),
  agpeya4: () => import("../data/agpeya/agpeya4.json"),
  agpeya5: () => import("../data/agpeya/agpeya5.json"),
  agpeya6: () => import("../data/agpeya/agpeya6.json"),
  agpeya7: () => import("../data/agpeya/agpeya7.json"),
  agpeya8: () => import("../data/agpeya/agpeya8.json"),
  agpeya9: () => import("../data/agpeya/agpeya9.json"),
  agpeya10: () => import("../data/agpeya/agpeya10.json"),
  agpeya_prayers: () => import("../data/agpeya/agpeya_prayers.json"),
  agpeya_confession: () => import("../data/agpeya/agpeya_confession.json"),
  agpeya_repentance: () => import("../data/agpeya/agpeya_repentance.json"),
  agpeya_communion: () => import("../data/agpeya/agpeya_communion.json"),
  agpeya_guidance: () => import("../data/agpeya/agpeya_guidance.json"),
  agpeya_meals: () => import("../data/agpeya/agpeya_meals.json"),
  agpeya_various: () => import("../data/agpeya/agpeya_various.json"),
  euchologion: () => import("../data/euchologion/euchologion.json"),
  stbasil: () => import("../data/euchologion/stbasil.json"),
  stbasil1: () => import("../data/euchologion/stbasil1.json"),
  stbasil2: () => import("../data/euchologion/stbasil2.json"),
  stbasil3: () => import("../data/euchologion/stbasil3.json"),
  stgregory: () => import("../data/euchologion/stgregory.json"),
  stgregory1: () => import("../data/euchologion/stgregory1.json"),
  stgregory2: () => import("../data/euchologion/stgregory2.json"),
  stgregory3: () => import("../data/euchologion/stgregory3.json"),
  stcyril: () => import("../data/euchologion/stcyril.json"),
  stcyril1: () => import("../data/euchologion/stcyril1.json"),
  stcyril2: () => import("../data/euchologion/stcyril2.json"),
  stcyril3: () => import("../data/euchologion/stcyril3.json"),
  matins: () => import("../data/euchologion/matins.json"),
  vesper: () => import("../data/euchologion/vesper.json"),
  liturgy: () => import("../data/euchologion/liturgy.json"),

  readings: () => import("../data/lesungen/readings.json"),
  matins_reading: () => import("../data/lesungen/matins_reading.json"),
  vesper_reading: () => import("../data/lesungen/vesper_reading.json"),
  liturgy_reading: () => import("../data/lesungen/liturgy_reading.json"),

  psalmodia: () => import("../data/psalmodia/psalmodia.json"),
  psalmodia_vesper: () => import("../data/psalmodia/psalmodia_vesper.json"),
  psalmodia_midnight: () => import("../data/psalmodia/psalmodia_midnight.json"),
  psalmodia_matins: () => import("../data/psalmodia/psalmodia_matins.json"),

  doxologien: () => import("../data/doxologien/doxologien.json"),
  doxologien_maria: () => import("../data/doxologien/doxologien_maria.json"),
  doxologien_maertyrer: () => import("../data/doxologien/doxologien_maertyrer.json"),
  doxologien_heilige: () => import("../data/doxologien/doxologien_heilige.json"),
  doxologien_anlaesse: () => import("../data/doxologien/doxologien_anlaesse.json"),

  funeral_general: () => import("../data/pascha/funeral_general.json"),
  pascha: () => import("../data/pascha/navigation/pascha.json"),
  pascha_sun: () => import("../data/pascha/navigation/pascha_sun.json"),
  pascha_sun_day: () => import("../data/pascha/navigation/pascha_sun_day.json"),
  pascha_mon: () => import("../data/pascha/navigation/pascha_mon.json"),
  pascha_mon_eve: () => import("../data/pascha/navigation/pascha_mon_eve.json"),
  pascha_mon_day: () => import("../data/pascha/navigation/pascha_mon_day.json"),
  pascha_tue: () => import("../data/pascha/navigation/pascha_tue.json"),
  pascha_tue_eve: () => import("../data/pascha/navigation/pascha_tue_eve.json"),
  pascha_tue_day: () => import("../data/pascha/navigation/pascha_tue_day.json"),
  pascha_wed: () => import("../data/pascha/navigation/pascha_wed.json"),
  pascha_wed_eve: () => import("../data/pascha/navigation/pascha_wed_eve.json"),
  pascha_wed_day: () => import("../data/pascha/navigation/pascha_wed_day.json"),
  pascha_thu: () => import("../data/pascha/navigation/pascha_thu.json"),
  pascha_thu_eve: () => import("../data/pascha/navigation/pascha_thu_eve.json"),
  pascha_thu_day: () => import("../data/pascha/navigation/pascha_thu_day.json"),
  pascha_fri: () => import("../data/pascha/navigation/pascha_fri.json"),
  pascha_fri_eve: () => import("../data/pascha/navigation/pascha_fri_eve.json"),

  pascha_expo_sun_day: () => import("../data/pascha/navigation/pascha_expo_sun_day.json"),
  pascha_expo_mon_eve: () => import("../data/pascha/navigation/pascha_expo_mon_eve.json"),
  pascha_expo_mon_day: () => import("../data/pascha/navigation/pascha_expo_mon_day.json"),
  pascha_expo_tue_eve: () => import("../data/pascha/navigation/pascha_expo_tue_eve.json"),
  pascha_expo_tue_day: () => import("../data/pascha/navigation/pascha_expo_tue_day.json"),
  pascha_expo_wed_eve: () => import("../data/pascha/navigation/pascha_expo_wed_eve.json"),
  pascha_expo_wed_day: () => import("../data/pascha/navigation/pascha_expo_wed_day.json"),
  pascha_expo_thu_eve: () => import("../data/pascha/navigation/pascha_expo_thu_eve.json"),
  pascha_expo_thu_day: () => import("../data/pascha/navigation/pascha_expo_thu_day.json"),
  pascha_expo_fri_day: () => import("../data/pascha/navigation/pascha_expo_fri_day.json"),
  pascha_expo_fri_eve: () => import("../data/pascha/navigation/pascha_expo_fri_eve.json"),

  pascha_sun_day_9: () => import("../data/pascha/pascha_sun_day_9.json"),
  pascha_sun_day_11: () => import("../data/pascha/pascha_sun_day_11.json"),
  pascha_mon_eve_1: () => import("../data/pascha/pascha_mon_eve_1.json"),
  pascha_mon_eve_3: () => import("../data/pascha/pascha_mon_eve_3.json"),
  pascha_mon_eve_6: () => import("../data/pascha/pascha_mon_eve_6.json"),
  pascha_mon_eve_9: () => import("../data/pascha/pascha_mon_eve_9.json"),
  pascha_mon_eve_11: () => import("../data/pascha/pascha_mon_eve_11.json"),
  pascha_mon_day_1: () => import("../data/pascha/pascha_mon_day_1.json"),
  pascha_mon_day_3: () => import("../data/pascha/pascha_mon_day_3.json"),
  pascha_mon_day_6: () => import("../data/pascha/pascha_mon_day_6.json"),
  pascha_mon_day_9: () => import("../data/pascha/pascha_mon_day_9.json"),
  pascha_mon_day_11: () => import("../data/pascha/pascha_mon_day_11.json"),
  pascha_tue_eve_1: () => import("../data/pascha/pascha_tue_eve_1.json"),
  pascha_tue_eve_3: () => import("../data/pascha/pascha_tue_eve_3.json"),
  pascha_tue_eve_6: () => import("../data/pascha/pascha_tue_eve_6.json"),
  pascha_tue_eve_9: () => import("../data/pascha/pascha_tue_eve_9.json"),
  pascha_tue_eve_11: () => import("../data/pascha/pascha_tue_eve_11.json"),
  pascha_tue_day_1: () => import("../data/pascha/pascha_tue_day_1.json"),
  pascha_tue_day_3: () => import("../data/pascha/pascha_tue_day_3.json"),
  pascha_tue_day_6: () => import("../data/pascha/pascha_tue_day_6.json"),
  pascha_tue_day_9: () => import("../data/pascha/pascha_tue_day_9.json"),
  pascha_tue_day_11: () => import("../data/pascha/pascha_tue_day_11.json"),
  pascha_wed_eve_1: () => import("../data/pascha/pascha_wed_eve_1.json"),
  pascha_wed_eve_3: () => import("../data/pascha/pascha_wed_eve_3.json"),
  pascha_wed_eve_6: () => import("../data/pascha/pascha_wed_eve_6.json"),
  pascha_wed_eve_9: () => import("../data/pascha/pascha_wed_eve_9.json"),
  pascha_wed_eve_11: () => import("../data/pascha/pascha_wed_eve_11.json"),
  pascha_wed_day_1: () => import("../data/pascha/pascha_wed_day_1.json"),
  pascha_wed_day_3: () => import("../data/pascha/pascha_wed_day_3.json"),
  pascha_wed_day_6: () => import("../data/pascha/pascha_wed_day_6.json"),
  pascha_wed_day_9: () => import("../data/pascha/pascha_wed_day_9.json"),
  pascha_wed_day_11: () => import("../data/pascha/pascha_wed_day_11.json"),
  pascha_thu_eve_1: () => import("../data/pascha/pascha_thu_eve_1.json"),
  pascha_thu_eve_3: () => import("../data/pascha/pascha_thu_eve_3.json"),
  pascha_thu_eve_6: () => import("../data/pascha/pascha_thu_eve_6.json"),
  pascha_thu_eve_9: () => import("../data/pascha/pascha_thu_eve_9.json"),
  pascha_thu_eve_11: () => import("../data/pascha/pascha_thu_eve_11.json"),
  pascha_thu_day_1: () => import("../data/pascha/pascha_thu_day_1.json"),
  pascha_thu_day_3: () => import("../data/pascha/pascha_thu_day_3.json"),
  pascha_thu_day_6: () => import("../data/pascha/pascha_thu_day_6.json"),
  pascha_thu_day_9: () => import("../data/pascha/pascha_thu_day_9.json"),
  pascha_thu_day_11: () => import("../data/pascha/pascha_thu_day_11.json"),
  pascha_fri_eve_1: () => import("../data/pascha/pascha_fri_eve_1.json"),
  pascha_fri_eve_3: () => import("../data/pascha/pascha_fri_eve_3.json"),
  pascha_fri_eve_6: () => import("../data/pascha/pascha_fri_eve_6.json"),
  pascha_fri_eve_9: () => import("../data/pascha/pascha_fri_eve_9.json"),
  pascha_fri_eve_11: () => import("../data/pascha/pascha_fri_eve_11.json"),
  pascha_fri_day_1: () => import("../data/pascha/pascha_fri_day_1.json"),
  pascha_fri_day_3: () => import("../data/pascha/pascha_fri_day_3.json"),
  pascha_fri_day_6: () => import("../data/pascha/pascha_fri_day_6.json"),
  pascha_fri_day_9: () => import("../data/pascha/pascha_fri_day_9.json"),
  pascha_fri_day_11: () => import("../data/pascha/pascha_fri_day_11.json"),
  pascha_fri_day_12: () => import("../data/pascha/pascha_fri_day_12.json"),

  pascha_conclusion_day: () => import("../data/pascha/pascha_conclusion_day.json"),
  pascha_conclusion_eve: () => import("../data/pascha/pascha_conclusion_eve.json"),
};

export const useBookStore = defineStore("books", {
  //=====================//
  //       State         //
  //=====================//
  state: () => ({
    books: [],
    rootBooks: [],
    selectedBookKey: "",
    selectedBook: null,
    prayers: [],
    metaDataCached: false,
    metaData: new Map([]),
  }),
  //=====================//
  //       Getters       //
  //=====================//
  getters: {
    bookTitle: (state) => (locale) => state.selectedBook?.titleDisplay[locale] ?? "No book selected",
    selectedBookImage: (state) => {
      // Selected book image
      return state.selectedBook?.image || state.selectedBookKey;
    },
    selectedBookImageMenu: (state) => {
      // Selected book image for menu
      return state.selectedBook?.image || `${state.selectedBookKey}_menu`;
    },
    getBooksWithBibleUsage: (state) => {
      return state.rootBooks.filter((b) => b.bible.usage == true);
    },
  },
  //====================//
  //       Action       //
  //====================//
  actions: {
    async getFile(fileName) {
      try {
        const fileContent = await (
          fileMap[fileName] || (() => Promise.reject(new Error(`File ${fileName} not found`)))
        )();
        return fileContent.default; // Access the JSON content
      } catch (error) {
        appLog(DebugLevel.ERROR, `[Pinia ${storeName}]`, `Error loading books from ${fileName}:`, error);
        throw error;
      }
    },
    async loadBook(bookName = "books") {
      if (bookName == null) bookName = "books";
      const book = await this.getFile(bookName);
      this.books = book.books;
      this.selectedBook = book;
      this.selectedBookKey = bookName;
      this.prayers = book.prayers;
      appLog(DebugLevel.TRACE, `[Pinia ${storeName}]`, `Loaded book ${bookName}`, JSON.stringify(this.books));
    },
    async setRootBooks() {
      const tempBooks = await this.getFile("books");
      this.rootBooks = tempBooks.books;
    },
    clearSelectedBook() {
      this.selectedBook = null;
      this.selectedBookKey = "";
      this.prayers = [];
    },
    async filterPrayers(bookName) {
      // load book this is required if we use deep links
      await this.loadBook(bookName);

      // load calendar store to get current church season, day of the week and current reading day
      const calStore = useCalendarStore();

      const { filteredPrayers } = useGetFilteredPrayers(
        this.selectedBook,
        calStore.getChurchSeason,
        calStore.getDayOfWeek,
        calStore.getReading
      );

      this.prayers = filteredPrayers;

      appLog(DebugLevel.DEBUG, `[Pinia ${storeName}]`, `Prayers Length:`, this.prayers.length);
    },
    async buildMetaDataCache(forceRefresh = false) {
      // check if already cached
      if (this.metaDataCached) {
        appLog(DebugLevel.DEBUG, `[Pinia ${storeName}]`, `MetaData Cache valid. NOT loading data`);
        return;
      }

      // copy object by using spread method
      const newMap = { ...fileMap };

      for (const [key] of Object.entries(newMap)) {
        appLog(DebugLevel.TRACE, `[Pinia ${storeName}]`, `MetaData Cache loading ${key}`);
        try {
          const response = JSON.parse(JSON.stringify(await this.getFile(key)));
          // remove non-required properties
          delete response.prayers;
          this.metaData.set(key, response);
        } catch (error) {
          appLog(DebugLevel.ERROR, `[Pinia ${storeName}]`, `Error loading ${key}:`, error);
        }
      }
      this.metaDataCached = true;
    },
  },
});

appLog(DebugLevel.INFO, "[Pinia]", `${storeName} initialized.`);
