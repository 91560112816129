<template>
  <ion-header>
    <ion-toolbar class="header">
      <ion-buttons slot="end"> <ion-button @click="cancel">X</ion-button></ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <VarusSettingsFontSize />
    <VarusParagraphText :paragraph="para" :cssstyleclass="para.type" :type="para.type" />
    <VarusSettingsDarkMode />
    <div class="button-container">
      <ion-button :strong="true" @click="confirm">OK</ion-button>
    </div>
  </ion-content>
</template>

<script setup>
import { IonContent, IonHeader, IonToolbar, IonButtons, IonButton, modalController } from "@ionic/vue";
import { ref } from "vue";
import VarusSettingsFontSize from "../VarusSettingsFontSize.vue";
import VarusSettingsDarkMode from "../VarusSettingsDarkMode.vue";
import VarusParagraphText from "../VarusParagraphText.vue";

const para = {
  type: "text",
  text: {
    deu: ["Ehre sei dem Vater und dem Sohn und dem Heiligen Geist."],
    ara: ["المجد للآب والابن والروح القدس"],
    cop: ["Ⲇⲟⲝⲁ Ⲡⲁⲧⲣⲓ ⲕⲉ Ⲩ̀ⲓⲱ ⲕⲉ ⲁ̀ⲅⲓⲱ̀ Ⲡ̀ⲛⲉⲩⲙⲁⲧⲓ"],
  },
};

const name = ref();
const cancel = () => modalController.dismiss(null, "cancel");
const confirm = () => modalController.dismiss(name.value, "confirm");
</script>
<style scoped>
.button-container {
  display: flex;
  justify-content: center;
}
</style>
