import { inRange, addToArray } from "../functions/GeneralUtils";
import { configs } from "../functions/ConfigUtil";
import { getMonthlyFeastByKey, getFeastByKey } from "../seasonservice/FeastService";
import { getFastByKey } from "../seasonservice/FastService";
import { FeastAndFastType } from "../enums/FeastAndFastType";
import { DebugLevel, appLog } from "../functions/Logging";

const fileName = "[TunesService]";
const getCurrentTunes = function (cDate, daysToEastern) {
  const year = cDate.y;
  const month = cDate.m;
  const day = cDate.d;

  let relevantTunes = [];
  appLog(DebugLevel.DEBUG, fileName, `getCurrentTunes ${year}-${month}-${day}`);
  configs.tunes.forEach((tune) => {
    appLog(DebugLevel.DEBUG, fileName, "====== " + tune.key + " =====");
    let rules = tune.rules;

    for (let index = 0; index < rules.length; index++) {
      let rule = rules[index];
      let ruleKey = Object.keys(rule)[0];
      switch (ruleKey) {
        case "dateRanges": {
          // iterate over dateRanges
          let rangeKeys = Object.keys(rule.dateRanges);
          appLog(DebugLevel.DEBUG, fileName, "==== ", rangeKeys);
          rangeKeys.forEach((dateRange) => {
            switch (dateRange) {
              case "nonMovable": {
                let nonMovables = rule.dateRanges.nonMovable;
                // appLog(DebugLevel.DEBUG, "nonMovables: ", nonMovables);
                for (const nonMovable of nonMovables) {
                  if (nonMovableInRange(nonMovable, day, month)) {
                    addToArray(relevantTunes, tune);
                  }
                }
                break;
              }
              case "movable": {
                let movables = rule.dateRanges.movable;
                // appLog(DebugLevel.DEBUG, fileName, "movables: ", movables);
                for (const movable of movables) {
                  if (movableInRange(movable, daysToEastern)) {
                    addToArray(relevantTunes, tune);
                  }
                }
                break;
              }
              case "monthly": {
                const monthly = rule.dateRanges.monthly;
                // appLog(DebugLevel.DEBUG, fileName, "rule monthly: ", monthly);
                for (const monthF of monthly) {
                  let feast = getMonthlyFeastByKey(monthF.key);
                  if (feast.monthlyDay == day && !nonMovableInRange(monthF.exception.nonMovable, day, month)) {
                    addToArray(relevantTunes, tune);
                  }
                }
                break;
              }
            }
          });
          break;
        }

        case "feasts": {
          rule.feasts.forEach((feast, index) => {
            let feastKey = rule.feasts[index].feast;
            if (!exceptionApplies(rule.feasts[index].exception, daysToEastern)) {
              // get actual feast data
              let feast = getFeastByKey(feastKey);
              if (feast.feast_type == FeastAndFastType.NON_MOVABLE && nonMovableInRange(feast, day, month)) {
                addToArray(relevantTunes, tune);
              } else if (feast.feast_type == FeastAndFastType.MOVABLE && feastMovableInRange(feast, daysToEastern)) {
                addToArray(relevantTunes, tune);
              }
            }
          });
          break;
        }

        case "fasts": {
          rule.fasts.forEach((fast, index) => {
            let fastKey = rule.fasts[index].fast;
            if (!exceptionApplies(rule.fasts[index].exception, daysToEastern)) {
              // get actual fast data
              let fast = getFastByKey(fastKey);
              if (fast.fast_type == FeastAndFastType.NON_MOVABLE && nonMovableInRange(fast, day, month)) {
                addToArray(relevantTunes, tune);
              } else if (fast.fast_type == FeastAndFastType.MOVABLE && fastMovableInRange(fast, daysToEastern)) {
                addToArray(relevantTunes, tune);
              }
            }
          });
          break;
        }
        default:
      }
    }
  });
  if (relevantTunes.length == 0) {
    // appLog(DebugLevel.DEBUG, fileName, "Adding Annual Tune");
    relevantTunes.push(configs.tunes.find((t) => t.key == "tune_annual"));
  }
  return relevantTunes;
};
const exceptionApplies = (exception, daysToEastern) => {
  if (exception && exception.exceptionType == "dateRange") {
    let movable = exception.exceptionType.movable;
    if (movable && feastMovableInRange(movable, daysToEastern)) return true;
    return false;
  } else {
    return false;
  }
};

const nonMovableInRange = (nonMovableRange, day, month) => {
  if (
    day >= nonMovableRange.dayStart &&
    day <= nonMovableRange.dayEnd &&
    month >= nonMovableRange.monthStart &&
    month <= nonMovableRange.monthEnd
  ) {
    return true;
  } else return false;
};

const movableInRange = (range, daysToEastern) => {
  if (inRange(range.daysToEasternStart, range.daysToEasternEnd, daysToEastern)) {
    return true;
  } else {
    return false;
  }
};

const feastMovableInRange = (feast, daysToEastern) => {
  if (inRange(feast.times.daysToEasternStart, feast.times.daysToEasternEnd, daysToEastern)) {
    return true;
  } else {
    return false;
  }
};

const fastMovableInRange = (fast, daysToEastern) => {
  if (inRange(fast.times.daysToEasternStart, fast.times.daysToEasternEnd, daysToEastern)) {
    return true;
  } else {
    return false;
  }
};

export { getCurrentTunes };
