<script>
export default {
  name: "VarusTableOfPrayers",
};
</script>
<script setup>
import { IonList } from "@ionic/vue";
import { ref, onBeforeMount, onBeforeUpdate, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { useScrollStore } from "../store/scrollStore.js";
import { DebugLevel, appLog } from "../functions/Logging";
import VarusSideMenuLink from "../components/VarusSideMenuLink.vue";
import Bugsnag from "@bugsnag/js";
import { useBookStore } from "../store/bookStore";

const bookStore = useBookStore();
const scrollStore = useScrollStore();

const divs = ref([]);
const tableOfContent = ref([]);

/**
 * This functions getts triggered upon scrolling in then main view (through pinia subscription to scrollStore). The function
 */
function synchronizeCurrentPrayerWithChildMenu() {
  appLog(DebugLevel.SCROLL, compName, "*** Event synchronizeScrollParameter");
  try {
    appLog(DebugLevel.SCROLL, compName, "scrollElementMain: ", scrollStore.scrollElementMain);
    appLog(DebugLevel.SCROLL, compName, "divs: ", divs);
    const elementToScrollTo = divs.value.find((element) => element.localName == scrollStore.scrollElementMain);
    if (elementToScrollTo != undefined && elementToScrollTo != elementToScrollTo.offsetTop) {
      scrollStore.YposMenuRight = elementToScrollTo.offsetTop;
      appLog(DebugLevel.SCROLL, compName, "Offset Top", elementToScrollTo.offsetTop);
      // need to check which works on  mobile browsers:
      // elementToScrollTo.getBoundingClientRect().top
      // elementToScrollTo.scrollTop
      // elementToScrollTo.offsetTop
      // elementToScrollTo.pageYOffset
      // scrolling:
      elementToScrollTo.scrollIntoView();
    }
  } catch (e) {
    Bugsnag.notify(e);
    // scrolling error - can usually be ignored :-)
    appLog(DebugLevel.ERROR, compName, "Scroll Error: " + e);
  }
}

/**
 * This functions getts triggered upon scrolling in then main view (through pinia subscription to scrollStore). The function
 */
// eslint-disable-next-line no-unused-vars
function scrollToElement() {
  appLog(DebugLevel.DEBUG, compName, "*** Click on 'Scroll to current chapter'");
  try {
    appLog(DebugLevel.DEBUG, compName, "scrollElementMain: ", scrollStore.scrollElementMain);
    appLog(DebugLevel.DEBUG, compName, "divs: ", divs);
    const elementToScrollTo = divs.value.find((element) => element.localName == scrollStore.scrollElementMain);
    appLog(DebugLevel.DEBUG, compName, "Scroll to Element", elementToScrollTo);
    if (elementToScrollTo != undefined && elementToScrollTo != elementToScrollTo.offsetTop) {
      // scrolling:
      elementToScrollTo.scrollIntoView();
    }
  } catch (e) {
    Bugsnag.notify(e);
    // scrolling error - can usually be ignored :-)
    appLog(DebugLevel.ERROR, compName, "Scroll Error: " + e);
  }
}

// Highlight active menu item
// https://github.com/vuejs/vue-router/issues/2040
//
// window.location.href                   = http://localhost:8100/prayerlist/agpeya1#psalm15
// window.location.origin                 = http://localhost:8100
// window.location.pathname (route.path)  = prayerlist/agpeya1
// window.location.hash                   = #psalm15
// window.location.hostname               = localhost
// window.location.protocol               = http:

// Logging
const compName = "[" + getCurrentInstance().type.name + "]";

onBeforeUpdate(() => {
  appLog(DebugLevel.HOOKS, compName, "Before Update");
  // we need to make sure to reset the refs before each update
  divs.value = [];
});

onBeforeMount(() => {
  appLog(DebugLevel.HOOKS, compName, "Before Mount");
  // Subscription of Scroll Store - meaning that changes to ScrollStore will trigger following scrollTo-Method
  scrollStore.$subscribe(() => {
    synchronizeCurrentPrayerWithChildMenu();
  });
});
</script>

<template>
  <ion-list lines="none" inset="false">
    <!--ion-item>
      <button @click="scrollToElement">Scroll to current prayer</button>
    </ion-item-->
  </ion-list>
  <ion-list lines="full">
    <!-- <br />1 <br />{{ tableOfContent }} <br />2 -->
    <span v-if="useRoute().meta.showRightMenu">
      <template v-for="child in bookStore.prayers" :key="child.key">
        <router-link
          :to="useRoute().path + '#' + child.key"
          class="noline"
          :class="child.disabled ? 'linkDisabled' : 'linkEnabled'"
          replace
        >
          <!-- removed this component, because its not clear whether its in use and how it works :-) -->
          <!--component
          :is="child.key"
          :ref="
            (el) => {
              divs[index] = el;
            }
          "
          -->
          <VarusSideMenuLink :element="child" sideMenuPosition="right" />
          <!--/component-->
        </router-link>
      </template>
    </span>
  </ion-list>
</template>
