import { Bugfender } from "@bugfender/sdk";
import { isPlatform } from "@ionic/vue";
import { Capacitor } from "@capacitor/core";

export const DebugLevel = Object.freeze({
  HOOKS: { name: "HOOKS", enabled: false },
  ROUTER: { name: "ROUTER", enabled: false },
  SCROLL: { name: "SCROLL", enabled: false },
  DEBUG: { name: "DEBUG", enabled: process.env.NODE_ENV == "development" ? true : false },
  TRACE: { name: "TRACE", enabled: false },
  INFO: { name: "INFO", enabled: false },
  WARN: { name: "WARN", enabled: false },
  ERROR: { name: "ERROR", enabled: true },
  FATAL: { name: "FATAL", enabled: true },
});

/**
 * concatenates all the elements of the log message to one string
 * @param {} level
 * @param {*} component
 * @param {*} message
 * @param {*} object
 * @returns
 */
function concatLogMessage(level, component, message, object) {
  let logArray = [];
  logArray.push(level);
  logArray.push(component);
  logArray.push(message);
  logArray.push(object);
  return logArray.join(" | ");
}

/**
 *
 * logging
 */
export function appLog(level, component, message, object) {
  if (!level.enabled) return;
  const objectOutput = object != undefined ? object : " ";
  switch (level.name) {
    case "WARN":
      console.warn(component, level.name, message, objectOutput);
      break;

    case "ERROR":
      console.error(component, level.name, message, objectOutput);
      break;

    case "DEBUG":
      console.info(component, level.name, message, objectOutput);
      break;

    case "TRACE":
      console.info(component, level.name, message, objectOutput);
      break;

    case "INFO":
      console.info(component, level.name, message, objectOutput);
      break;

    default:
      console.log(component, level.name, message, objectOutput);
      break;
  }

  // Bugfender Logging
  if (isPlatform("mobile") && Capacitor.getPlatform() != "web") {
    const logMessage = concatLogMessage(component, level.name, message, objectOutput);
    Bugfender.log(logMessage);
  }
}
