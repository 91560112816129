<script>
export default {
  name: "VarusStoreBookInfo",
};
</script>
<script setup>
import { useBookStore } from "../store/bookStore";
import { storeToRefs } from "pinia";
const bookStore = useBookStore();
const { selectedBookKey, selectedBook, prayers, selectedBookImage, selectedBookImageMenu, books } =
  storeToRefs(bookStore);
</script>

<template>
  <h6>
    <br /><br />
    books: {{ books }} <br /><br />
    selectedBookKey: {{ selectedBookKey }} <br /><br />
    bookTitle: {{ bookStore.bookTitle("deu") }} <br /><br />
    <br /><br />
    selectedBook:
    <pre>{{ selectedBook }}</pre>
    <br /><br />
    prayers: {{ prayers }} <br /><br />
  </h6>
</template>
