<script setup>
import { IonList } from "@ionic/vue";
import {
  bugOutline,
  calendarNumberOutline,
  homeOutline,
  informationCircleOutline,
  settingsOutline,
} from "ionicons/icons";
import VarusMenuOption from "./VarusMenuOption.vue";
// eslint-disable-next-line no-unused-vars

const options = [
  {
    title: "ui.home",
    url: "/home",
    icon: homeOutline,
    enabled: true,
    onlyOnMobile: false,
  },
  {
    type: "menuHeader",
    title: "ui.relatedLinks",
    enabled: false,
  },
  {
    title: "ui.churchseason",
    url: "/churchSeason",
    icon: calendarNumberOutline,
    enabled: false,
    onlyOnMobile: false,
  },
  {
    title: "ui.settings.settings",
    url: "/settings",
    icon: settingsOutline,
    enabled: true,
    onlyOnMobile: false,
  },
  {
    title: "ui.info",
    url: "/info",
    icon: informationCircleOutline,
    enabled: true,
    onlyOnMobile: false,
  },
  {
    title: "ui.reportbug.title",
    url: "/reportbug",
    icon: bugOutline,
    enabled: true,
    onlyOnMobile: false,
  },
];
</script>

<template>
  <ion-list id="labels-list" lines="full">
    <VarusMenuOption
      v-for="(option, i) in options"
      :type="option.type"
      :key="i"
      :linkPath="option.url"
      :display="option.enabled"
      :title="option.title"
      :icon="option.icon"
      :onlyOnMobile="option.onlyOnMobile"
      :onlyInDevMode="option.onlyInDevMode"
    />
  </ion-list>
  <!-- Parents Menu disabled because of bug -->
  <!-- VarusParents /-->
</template>
