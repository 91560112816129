<script setup>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useCalendarStore } from "../store/calendarStore";

const calendarStore = useCalendarStore();
const { getReading } = storeToRefs(calendarStore);
</script>

<template>
  <ion-grid>
    <ion-row>
      <ion-col size="auto">
        <h6>{{ getReading.title }}</h6>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <pre>{{ getReading }}</pre>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
