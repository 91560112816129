// Import Basics
import { createApp } from "vue";
import { IonicVue, isPlatform } from "@ionic/vue";
import { Bugfender } from "@bugfender/sdk";
import { Capacitor } from "@capacitor/core";

// Import Components
import App from "./App.vue";
import BaseLayout from "./components/base/BaseLayout.vue";

// Import Plugins & Tools
import router from "./router";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";

// Book Store
import { useBookStore } from "./store/bookStore";

// ** CSS **
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Ionic Dark Mode */
import "@ionic/vue/css/palettes/dark.class.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/core.css";

//
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Bugsnag.start({
  apiKey: "8157b2f07ab2a40f169aa700da62b6f0",
  plugins: [new BugsnagPluginVue()],
  appVersion: process.env.VUE_APP_VERSION,
  otherOptions: "value",
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
});
const bugsnagVue = Bugsnag.getPlugin("vue");

// i18n
const messages = {
  deu: JSON.parse(JSON.stringify(require(`./locales/deu.json`))),
  ara: JSON.parse(JSON.stringify(require(`./locales/ara.json`))),
  eng: JSON.parse(JSON.stringify(require(`./locales/eng.json`))),
};
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "deu",
  messages,
});
const app = createApp(App).use(bugsnagVue).use(i18n).use(IonicVue).use(router).use(createPinia());

app.component("base-layout", BaseLayout);
app.provide(/* key */ "message", /* value */ "Message provided by main.js and injected here");
app.provide("$i18n", i18n);

router.isReady().then(() => {
  app.mount("#app");
});

// Global variables
app.config.globalProperties.$nodeMode = process.env.NODE_ENV;
app.config.globalProperties.$appVersion = process.env.VUE_APP_VERSION;

// Bugfender Initialize
Bugfender.init({
  appKey: "E81xybxn6J94ohJDHRlxs6JlGWVbuufT",
  logBrowserEvents: process.env.NODE_ENV !== "production",
  logUIEvents: process.env.NODE_ENV === "development",
  build: process.env.VUE_APP_VERSION,
});

// Bugfender - catch unhandled errors and report them to Bugfender - custom Vue errorHandler
app.config.errorHandler = (err, _, info) => {
  if (err instanceof Error) {
    Bugfender.sendCrash(
      `${err.name}: ${err.message ?? "N/A"}`,
      `Vue error info: ${info}\nStack: ${err.stack ?? "N/A"}`
    );
  } else {
    Bugfender.sendCrash(String(err), `Vue error info: ${info}`);
  }
};

// Building metadata cache
const bookStore = useBookStore();
await bookStore.buildMetaDataCache();
await bookStore.setRootBooks();
