import { ref } from "vue";
import { DebugLevel, appLog } from "../functions/Logging";
import { getPrayer, getPsalm } from "../services/PrayerImportService";
import { getReadingAsPrayer2 } from "../services/ReadingServiceNew";

/**
 *
 * The functions in this file are compoable functions (encapsulate and reuse stateful logic).
 * They are passed an reactive state (e.g. church season) and the composable does create a watchers that retrieves the season-depending prayers using the passed state.
 *
 */

const FILE_NAME = "[PrayerFilterService]";
const applyRules = true;

// filter-prayer
export function useGetFilteredPrayers(book, season, dayOfWeek, reading) {
  const filteredPrayers = ref([]);

  // Reactive computation of filtered prayers
  const filterPrayers = (book, season, dayOfWeek, reading) => {
    if (!book || !book.prayers) {
      return [];
    }

    const prayers = [];
    book.prayers.forEach((prayer) => {
      if (prayer.disabled) {
        return;
      }

      if (book.prayersToBeFiltered && !applySeasonRules(prayer, season, dayOfWeek)) {
        return;
      }

      let tempPrayer;

      switch (prayer.type) {
        case "insertPsalm":
          tempPrayer = getPsalm(prayer);
          if (tempPrayer) {
            prayers.push(tempPrayer);
          }
          break;
        case "insertPrayer":
          tempPrayer = getPrayer(prayer);
          if (tempPrayer) {
            prayers.push(...tempPrayer);
          }
          break;
        case "insertReading":
          const service = prayer.service;
          const rType = prayer.key;
          const readingOfService = reading.liturgyServices[service][rType];
          const readingAsPrayer = getReadingAsPrayer2(rType, readingOfService);
          prayers.push(...readingAsPrayer);
          break;
        default:
          prayers.push(prayer);
          break;
      }

      // iterate through the paragraphs and filter
      if (typeof prayer.paragraphs !== "undefined") {
        prayer.paragraphs = getFilteredParagraphs(prayer.paragraphs, season, dayOfWeek);
      }
    });

    return prayers;
  };

  filteredPrayers.value = filterPrayers(book, season, dayOfWeek, reading);

  return { filteredPrayers };
}

function getFilteredParagraphs(paragraphs, season, dayOfWeek) {
  let filteredParagraphs = [];

  paragraphs.forEach((paragraph) => {
    // skip disabled paragraphs
    if (paragraph.disabled) {
      return;
    }

    const tempParagraph = applySeasonRules(paragraph, season, dayOfWeek);
    if (typeof tempParagraph !== "undefined") {
      filteredParagraphs.push(tempParagraph);
    }
  });
  return filteredParagraphs;
}

function applySeasonRules(prayerOrParagraph, season, dayOfWeek) {
  if (applyRules) {
    let display = [];

    if (prayerOrParagraph.displayWhen === undefined) return prayerOrParagraph;
    if (!Array.isArray(prayerOrParagraph.displayWhen)) return prayerOrParagraph;
    if (prayerOrParagraph.displayWhen.length <= 0) return prayerOrParagraph;

    prayerOrParagraph.displayWhen.forEach((rule) => {
      appLog(DebugLevel.TRACE, FILE_NAME, "element", rule);

      if (typeof rule.season_is !== "undefined") {
        if (rule.season_is.includes(season.key)) {
          display.push(true);
        } else {
          display.push(false);
        }
      }

      // dayOfWeek - Check
      if (typeof rule.dayOfWeek !== "undefined") {
        if (rule.dayOfWeek.includes(dayOfWeek)) {
          display.push(true);
        } else {
          display.push(false);
        }
      }

      // Feast - Check
      if (typeof rule.feast !== "undefined") {
        if (rule.feast == season.feast) {
          display.push(true);
        } else {
          display.push(false);
        }
      }
    });

    // check whether all rules are true
    if (display.includes(false)) {
      return;
    } else {
      return prayerOrParagraph;
    }
  }

  return prayerOrParagraph;
}
