import { loadingController } from "@ionic/vue";
import { ref } from "vue";

const loading = ref(null);

export default {
  async presentLoading(message = "Loading ...") {
    loading.value = await loadingController.create({
      message,
      spinner: "lines-small",
    });
    await loading.value.present();
    return loading;
  },
  async dismissLoading(loading) {
    if (loading) {
      await loading.value.dismiss();
    }
  },
};
