<script setup>
import { watch, ref, computed } from "vue";
import useEventsBus from "../services/EventBusService";
import { EMIT_FONTSIZE } from "../services/EventBusConstants";
import { storeToRefs } from "pinia";
import { useUserSettingsStore } from "../store/userSettingsStore";

// pinia Store - get userSettings from Store
const userSettingsStore = useUserSettingsStore();
const { baseFontSize } = storeToRefs(userSettingsStore);

const props = defineProps({
  texttodisplay: { required: true },
  lang: {
    type: Object,
    required: true,
  },
  cssClass: {
    type: String,
    required: false,
  },
});

const textSizeUnit = "rem";
const baseSize = ref(baseFontSize);

const germanBase = 0.1;
const arabicBase = 0.1;
const copticBase = 0.3;

const titleAdd = 0.1;
const commentAdd = -0.2;
const introAdd = -0.2;

// Font Size`s - Text

const sizeGerman = computed(() => {
  return parseFloat(baseSize.value) + germanBase + textSizeUnit;
});
const sizeArabic = computed(() => {
  return parseFloat(baseSize.value) + arabicBase + textSizeUnit;
});
const sizeCoptic = computed(() => {
  return parseFloat(baseSize.value) + copticBase + textSizeUnit;
});

// Font Size`s - Title
const sizeGermanTitle = computed(() => {
  return parseFloat(sizeGerman.value) + titleAdd + textSizeUnit;
});

const sizeArabicTitle = computed(() => {
  return parseFloat(sizeArabic.value) + titleAdd + textSizeUnit;
});

const sizeCopticTitle = computed(() => {
  return parseFloat(sizeCoptic.value) + titleAdd + textSizeUnit;
});

// Font Size`s - Comments

const sizeNonCopticComment = computed(() => {
  return baseSize.value + commentAdd + textSizeUnit;
});
const sizeNonCopticIntro = computed(() => {
  return baseSize.value + introAdd + textSizeUnit;
});

// receive emit
const { bus } = useEventsBus();
watch(
  () => bus.value.get(EMIT_FONTSIZE),
  (val) => {
    const [EMIT_FONTSIZE] = val ?? [];
    baseSize.value = EMIT_FONTSIZE;
    console.log("New Size: " + baseSize.value);
  }
);

// if texttodisplay is a non-array, make it an array with a single element
let textArray = [];
if (Array.isArray(props.texttodisplay)) {
  textArray = props.texttodisplay;
} else {
  textArray = [props.texttodisplay];
}
</script>
<template>
  <div
    :dir="lang.direction"
    :class="cssClass ? cssClass : lang.cssText"
    :lang="lang.iso631_1"
    :xml:lang="lang.iso631_1"
  >
    <template v-for="(line, index) in textArray" :key="index">
      <span v-if="typeof line === 'string'" v-html="line.concat(' ')"></span>
      <span v-else v-html="line"></span>
    </template>
  </div>
</template>
<style>
.germanText {
  line-height: 1.1;
  font-weight: normal;
  font-size: v-bind("sizeGerman");
  text-align: justify;
  text-justify: inter-word;
  hyphens: auto;
  -webkit-hyphens: auto;
}
.arabicText {
  line-height: 1.2;
  font-weight: normal;
  font-size: v-bind("sizeArabic");
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
}
.copticText1 {
  font-family: FreeSerifAthanasius !important;
  line-height: 0.95;
  font-weight: normal;
  font-size: v-bind("sizeCoptic");
  text-align: justify;
  text-justify: inter-word;
  hyphens: auto;
  -webkit-hyphens: auto;
}

.copticTitle1 {
  font-family: FreeSerifAthanasius !important;
  line-height: 1.2;
  font-weight: normal;
  font-size: v-bind("sizeCopticTitle");
}

.germanTitle {
  line-height: 1.3;
  font-weight: bold;
  font-size: v-bind("sizeGermanTitle");
  tab-size: 20;
  white-space: pre;
  white-space: normal;
  hyphens: auto;
}

.arabicTitle {
  line-height: 1.8;
  font-weight: bold;
  font-size: v-bind("sizeArabicTitle");
}

.comment {
  font-size: v-bind("sizeNonCopticComment");
}

/* for intro/extro of readings */
.intro {
  font-family: Georgia;
  font-size: v-bind("sizeNonCopticIntro");
  margin-left: 0px;
  font-style: italic;
}
</style>
